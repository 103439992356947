.options-cards{
    border: 2px solid black;
    cursor: pointer;
}

.options-cards:hover {
    border: 2px solid green;
    transform: scale(1.01);
}

.answered-cards{
    border: 2px solid green;
}

.answered-description > ol {
    flex-direction: column;
}